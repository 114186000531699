import '@hotwired/turbo-rails'

import { Application } from "@hotwired/stimulus"
import "flowbite/dist/flowbite.turbo.js";

window.app = {};
window.app.config = {};

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

import * as Routes from '../routes';
window.Routes = Routes;

import '../modules/header'
import '../modules/footer'
import '../modules/map'
import '../modules/search'
import '../modules/news'
import '../modules/vacancies'