import autoComplete from "@tarekraafat/autocomplete.js";

let indexAutoComplete, typeTimer

function _getMultiSearchResults(query) {
  return fetch(window.Routes.ajax_multi_search_path() + '?' + new URLSearchParams({
    search_query: query
  }), {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  });
}

const initIndexSearch = () => {
  indexAutoComplete = new autoComplete({
    selector: "#indexAutoComplete",
    placeHolder: "Введите название услуги, направления или имя врача",
    debounce: 500,
    threshold: 3,
    data: {
      src: async(query) => {
        try {
          const
            response = await _getMultiSearchResults(query),
            data = await response.json()

          data.forEach((el) => {
            el.searchable = query
          })

          return data
        } catch (error) {
          return error;
        }
      },
      keys: ['searchable'],
    },
    resultsList: {
        element: (list, data) => {
            list.id = "search-results"
            list.classList.add('shadowed')
            const categories = data.results.reduce((acc, el) => {
              if (!acc.includes(el.value.type)) {
                acc.push(el.value.type)
              }
              return acc
            }, [])
            categories.forEach(category => {
              const cat = document.createElement("div");
              const catTitle = document.createElement("h2");
              cat.setAttribute("id", category);
              catTitle.innerHTML = category;
              cat.appendChild(catTitle)
              list.prepend(cat);

              data.results.forEach((el) => {
                if (el.value.type === category) {
                  const li = document.createElement('li');
                  li.innerHTML = `<a class="search-item" href="${el.value.link}">${el.value.title}</a>`;
                  cat.appendChild(li)
                }
              })
            });
            if (!data.results.length) {
              list.innerHTML = `<span>Ничего не найдено</span>`;
            }

        },
        noResults: true,
        maxResults: 125
    },
    resultItem: {
      element: (item, data) => {
        item.remove()
      },
    },
    events: {
        input: {
            selection: (event) => {},
            focus: () => {
              const inputValue = indexAutoComplete.input.value;

              if (inputValue.length) indexAutoComplete.start();
          },
        }
    }
  });
}

const initSpecialistSearch = () => {
  const
    specialistFinder = document.getElementById('specialistFinder'),
    urlParams = new URLSearchParams(window.location.search),
    query = urlParams.get('q')

  if (query) {
    specialistFinder.value = query
  }

  specialistFinder.focus()

  specialistFinder.addEventListener('input', (e) => {
    clearTimeout(typeTimer)
    typeTimer = setTimeout(() => {
      Turbo.visit(window.Routes.specialists_path({q: e.target.value}));
    }, 700);
  })
}

window.addEventListener('turbo:load', (e) => {
  if (document.getElementById('indexAutoComplete')) {
    initIndexSearch();
  }

  if (document.getElementById('specialistFinder')) {
    initSpecialistSearch();
  }
})
