window.addEventListener('turbo:load', (e) => {
  if (document.getElementById('cookie-consent-dismiss')) {
    initListeners();
  }
})

const initListeners = () => {
  document.getElementById('cookie-consent-dismiss').addEventListener('click', (e) => {
    document.cookie = 'cookie_consent_accepted=1; expires=365; path=/';
    document.getElementById('cookie-consent').remove();
  })
}