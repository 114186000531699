import IMask from 'imask';

window.addEventListener('turbo:load', (e) => {
  initMasks();
})

const initMasks = () => {
  if (document.getElementById('phone')) {
    IMask(document.getElementById('phone'), {mask: '+{7}(000)000-00-00'});
  }


  if (document.getElementById('patient_phone')) {
    IMask(document.getElementById('patient_phone'), {mask: '+{7}(000)000-00-00'});
  }
}
